import { DropdownOption } from 'components/dropdown/component'

export const SOURCE_REFRESHED = 'Refresh request sent.'
export const UPCOMING_ARTICLE_MOVED = 'The article has been added to your upcoming articles.'
export const RESERVED_ARTICLE_MOVED = 'The article has been added to your reserve pool.'
export const UPCOMING_ARTICLE_MOVED_ERROR = 'Uh oh - something went wrong!'
export const SOURCE_ADDED_APPCUES_ID = '-M1a_F_Sbk6vkHp9Bfn3'
export const ARTICLE_BULK_ACTIVATION_SUCCESS = 'All articles have been activated successfully!'
export const ARTICLE_BULK_DEACTIVATION_SUCCESS = 'All articles have been deactivated successfully!'
export const ARTICLE_BULK_UPDATE_FAILURE = 'All articles have been deactivated successfully!'
export const ARTICLES_BULK_ARCHIVE_WARNING = 'If you click okay, you will deactivate selected articles. Continue?'
export const ARTICLE_SECTION_UPDATE_SUCCESS = 'Section updated successfully.'
export const ARTICLE_SECTION_UPDATE_FAILURE = 'Uh oh - something went wrong!'
export const ARTICLE_BULK_AI_IMAGE_GENERATION_MESSAGE = 'Images are being generated. Check back soon.'
export const ARTICLE_BULK_AI_IMAGE_GENERATION_FAILURE_MESSAGE = 'Unable to generate images at the moment. Please try again later.'
export const ARTICLE_BULK_AI_DESCRIPTION_GENERATION_MESSAGE = 'Descriptions are being generated. Check back soon.'
export const ARTICLE_BULK_AI_DESCRIPTION_GENERATION_FAILURE_MESSAGE = 'Unable to generate description at the moment. Please try again later.'
export const BULK_AI_IMAGE_GENERATION_COUNT = 10
export const BULK_AI_DESCRIPTION_GENERATION_COUNT = 20
export const IMAGE_TOO_SMALL_TOOLTIP = 'This image is too small to be displayed in a newsletter. Consider replacing it.'
export const IMAGE_MAY_BE_TOO_SMALL_TOOLTIP = 'This image may not fit the requirements of your current template. Consider replacing it.'
export const IMAGE_MAY_BE_TOO_SMALL_TEMPLATE_TOOLTIP = 'This is a small image, and it may not display depending on which template you select.'

export const EMPTY_FILE = 'Empty file: please check the uploaded file.'
export const INVALID_HEADERS = 'Invalid headers: please make sure to use the standard template.'
export const SUCCESSFUL_UPLOAD = 'Sources upload successful!'
export const SUCCESSFUL_DELETE = 'Source deleted!'
export const UNSUCCESSFUL_DELETE = 'Oops something went wrong!'
export const BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_WIDTH = 650
export const BIG_AND_BOLD_TEMPLATE_MIN_IMAGE_HEIGHT = 400
export const NEWSPAPER_TEMPLATE_MIN_IMAGE_WIDTH = 650
export const NEWSPAPER_TEMPLATE_MIN_IMAGE_HEIGHT = 400
export const CLASSIC_TEMPLATE_MIN_IMAGE_WIDTH = 200
export const CLASSIC_TEMPLATE_MIN_IMAGE_HEIGHT = 200
export const INSIGHTS_TEMPLATE_MIN_IMAGE_WIDTH = 200
export const INSIGHTS_TEMPLATE_MIN_IMAGE_HEIGHT = 200
export const GRID_MARGIN_WIDTH = 17
export const DEFAULT_ARTICLE_DESCRIPTION_CAP = 200
export const DEFAULT_SECTION_NAME = 'Content'
export const DEFAULT_CATEGORY_TYPE_NAME = 'Content'
export const DEFAULT_CATEGORY_TYPE_ID = 1
export const DEFAULT_SEGMENT_CODE = 'All'
export const AI_RECOMMENDATION_BLACK_LIST_TAG = 'ai_recommendation'
export const ZOOM = 'Zoom'

export const ArticleType = {
  reserved: {name: 'reserved', displayName: 'Reserved', route: 'reserved'},
}

export const ShowAllDropdownOption: DropdownOption = {
  key: 'Show all past articles',
  value: '-1',
}

export const BULK_SOURCES_IMPORT_TEMPLATE_LOCATION: string =
  'https://s3-us-west-2.amazonaws.com/public.rasa.io/imports_sample/bulk_sources_import_template.csv'

export const INTEGRAGTIONS_CONFIG_PAGE = '/settings/integrations/config'
export const CONTENT_ADD_SOURCE_PAGE = '/content/addsource'
export const CONTENT_ADD_INSTAGRAM_PAGE = '/content/add-instagram'
export const CONTENT_ADD_LINKEDIN_PAGE = '/content/add-linkedin'
export const CONTENT_ADD_ZOOM_PAGE = '/content/add-zoom'

export enum FilterOption {
  // Important that default also be 0 - so "not specified" is same as "0" as "falsey" values
  // And these should be strings - since that's what the underlying properties are
  SELECT = '-1',
  SOURCE = '0',
  SECTION = '1',
  SEGMENT = '2',
  NO_IMAGE = '3',
  IMAGE_WARNING = '4',
  TOGGLE_ON_OFF = '5',
  AI_RECOMMEND_DEACTIVATION = '6',
  NO_DESCRIPTION = '7',
  AI_GENERATED_IMAGE = '8',
  AI_GENERATED_DESCRIPTION = '9',
}
export const AllFilters: DropdownOption[] = [
  {
    description: 'Select',
    key: FilterOption.SELECT,
    value: FilterOption.SELECT,
  },
  {
    description: 'Sources',
    key: FilterOption.SOURCE,
    value: FilterOption.SOURCE,
  },
  {
    description: 'Sections',
    key: FilterOption.SECTION,
    value: FilterOption.SECTION,
  },
  {
    description: 'Segments',
    key: FilterOption.SEGMENT,
    value: FilterOption.SEGMENT,
  },
  {
    description: 'No Image',
    key: FilterOption.NO_IMAGE,
    value: FilterOption.NO_IMAGE,
  },
  {
    description: 'No Description',
    key: FilterOption.NO_DESCRIPTION,
    value: FilterOption.NO_DESCRIPTION,
  },
  {
    description: 'Image Warning',
    key: FilterOption.IMAGE_WARNING,
    value: FilterOption.IMAGE_WARNING,
  },
  {
    description: 'Manually Changed Articles',
    key: FilterOption.TOGGLE_ON_OFF,
    value: FilterOption.TOGGLE_ON_OFF,
  },
  {
    description: 'AI Recommended Deactivation',
    key: FilterOption.AI_RECOMMEND_DEACTIVATION,
    value: FilterOption.AI_RECOMMEND_DEACTIVATION,
  },
  {
    description: 'AI Generated Image',
    key: FilterOption.AI_GENERATED_IMAGE,
    value: FilterOption.AI_GENERATED_IMAGE,
  },
  {
    description: 'AI Generated Description',
    key: FilterOption.AI_GENERATED_DESCRIPTION,
    value: FilterOption.AI_GENERATED_DESCRIPTION,
  },
]

export const NO_SECTION = 'No Section'

export enum Font {
  SMALL = 'small',
  LARGE = 'large',
}
