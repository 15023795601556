import * as AuthConstants from 'auth/constants'
import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import {uniq} from 'lodash'
import {every, property} from 'lodash/fp'
import * as ReduxObservable from 'redux-observable'
import {ON_ENTER} from 'router'
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators'

import * as Actions from './actions'
import * as Constants from './constants'
import {FilteredSubscription} from './types'

const getCommunityId = (user) => user.init().then(({activeCommunity}) => activeCommunity.communityId)

const allSourceFiltersActive = every((sf: FilteredSubscription) => sf.is_active)

export const onEnterLoad: ReduxObservable.Epic = (action$, state$, {history, user}) =>
  action$.ofType(ON_ENTER).pipe(
    filter(() => history.location.pathname === Constants.CONTENT_POOL_FILTERS),
    switchMap(() => Promise.all([
      getCommunityId(user),
      window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
    ])),
    map(([communityId, authToken]) => Actions.loadCommunityFiltersDataset(communityId, authToken)),
  )

export const onEnterCue: ReduxObservable.Epic = (action$, state$, {history, user}) =>
  action$.ofType(ON_ENTER).pipe(
    filter(() => history.location.pathname === Constants.CONTENT_POOL_FILTERS),
    map(() => showAppCue(APP_CUE_TYPE.FILTER_GUIDE)),
  )

export const loadSourceFilters: ReduxObservable.Epic = (action$, state$, {user, entityMetadata}) =>
  action$.ofType(Constants.LOAD_FILTERED_SOURCES, Constants.FILTER_ADDED).pipe(
    switchMap((action) => Promise.all([
      getCommunityId(user),
      action.filterGroupId,
      window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
    ])),
    map(([communityId, filterGroupId, authToken]) =>
      Actions.loadCommunityFilterSubscriptionsDataset(communityId, filterGroupId, authToken)),
  )

export const addFilter: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.ADD_FILTER).pipe(
    switchMap((action) =>
      Promise.all([
        getCommunityId(user),
        entityMetadata.getEntityObject('community_filter'),
        entityMetadata.getEntityObject('community_filter_tag'),
        action,
      ]),
    ),
    switchMap(([communityId, filterEntity, tagEntity, {id, filterType, tags}]) => {
      const tagArray = uniq(tags.split(',').map((tag) => tag.trim()).filter((tag) => tag.length > 0))
      const tagName = tagArray.join(',')
      filterEntity.setRecordId(communityId, null)
      filterEntity.data.communityId = communityId
      filterEntity.data.filterType = filterType
      filterEntity.data.name = tagName.substr(0, 100)

      return filterEntity
        .save()
        .then(() => {
          return Promise.all(
            tagArray.map((tag) => {
              tagEntity.setRecordId(communityId, null)
              tagEntity.data.filterGroupId = filterEntity.data.id
              tagEntity.data.tag = tag
              return tagEntity.save()
            }),
          )
        })
        .then(() => [filterEntity.data.id, tagName])
    }),
    map(([filterGroupId, tagName]) => ({type: Constants.FILTER_ADDED, filterGroupId, tagName})),
  )

export const filterAdded: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.FILTER_ADDED).pipe(
    map((action) => Actions.modalActions.openModal(Constants.FILTER_ADDED, {}, 1)),
  )

export const editFilter: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.EDIT_FILTER).pipe(
    switchMap((action) =>
      Promise.all([getCommunityId(user), entityMetadata.getEntityObject('community_filter'), action]),
    ),
    switchMap(([communityId, entity, action]) => {
      entity.setRecordId(communityId, action.id)
      Object.assign(entity.data, action)

      return entity.save()
    }),
    map(() => ({type: Constants.FILTER_EDITED})),
  )

export const updateFilter: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.UPDATE_FILTER).pipe(
    switchMap((action) =>
      Promise.all([
        getCommunityId(user),
        entityMetadata.getEntityObject('community_filter'),
        entityMetadata.getEntityObject('community_filter_tag'),
        action,
      ]),
    ),
    switchMap(([communityId, filterEntity, tagEntity, {id, tags}]) => {
      const tagArray = uniq(tags.split(',').map((tag) => tag.trim()).filter((tag) => tag.length > 0))
      const tagName = tagArray.join(',').substr(0, 100)
      filterEntity.setRecordId(communityId, id)
      filterEntity.data.tags = tagArray.join(',')
      filterEntity.data.name = tagName

      return filterEntity
        .save()
        .then(() => {
          return Promise.all(
            tagArray.map((tag) => {
              tagEntity.setRecordId(communityId, null)
              tagEntity.data.filterGroupId = id
              tagEntity.data.tag = tag
              return tagEntity.save()
            }),
          )
        })
        .then(() => [id, tagName])
    }),
    map(([filterGroupId]) => ({type: Constants.FILTER_UPDATED, filterGroupId})),
  )

export const upsertSourceFilter: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.TOGGLE_SOURCE_FILTER).pipe(
    switchMap((action) =>
      Promise.all([getCommunityId(user), entityMetadata.getEntityObject('community_filtered_subscription'), action]),
    ),
    switchMap(([communityId, entity, {id, ...payload}]) => {
      entity.setRecordId(communityId, id)
      Object.assign(entity.data, payload)

      return entity.save().then(() => ({
        type: entity.data.id ? Constants.SOURCE_FILTER_CREATED : Constants.SOURCE_FILTER_UPDATED,
        id: entity.data.id || id,
        subscriptionId: payload.subscriptionId,
        filterGroupId: payload.filterGroupId,
      }))
    }),
  )

export const syncFilter: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.SOURCE_FILTER_CREATED, Constants.SOURCE_FILTER_UPDATED).pipe(
    withLatestFrom(state$),
    map(([action, state]) => {
      const associatedFilter = state.community_filters.data.data[action.filterGroupId]
      const appliesTo = allSourceFiltersActive(associatedFilter.subscriptions) ?
        Constants.FILTER_APPLIES_ALL : Constants.FILTER_APPLIES_SELECTED
      const needsUpdate = associatedFilter.applies_to !== appliesTo

      return {filterGroupId: associatedFilter.id, needsUpdate, appliesTo}
    }),
    filter(property('needsUpdate')),
    map(({filterGroupId, appliesTo}) => ({
      type: Constants.EDIT_FILTER,
      id: filterGroupId,
      appliesTo,
    })),
  )
export const bulkFilterApplied: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.APPLY_BULK_FILTER).pipe(
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      Promise.all([getCommunityId(user), entityMetadata.getEntityObject('community_filter'), action, state]),
    ),
    switchMap(([communityId, entity, action, state]) => {
      entity.setRecordId(communityId, action.id)
      Object.assign(entity.data, action)
      return entity.save().then(() => [action, state])
    }),
    map(([action, state]) => {
      const associatedFilter = state.community_filters.data.data[action.id]
      if (associatedFilter.subscriptions) {
        associatedFilter.subscriptions.map((x) => x.is_active = action.isActive)
      }
      associatedFilter.active_count = action.isActive ? associatedFilter.total_count : 0
      associatedFilter.applies_to = action.appliesTo ? action.appliesTo : associatedFilter.applies_to
      return {associatedFilter}
    }),
    map(({associatedFilter}) => ({
      type: Constants.BULK_FILTER_APPLIED,
      applyToFilter: associatedFilter,
    })),
  )
export const enterEdit: ReduxObservable.Epic = (action$, state$, {entityMetadata, user}) =>
  action$.ofType(Constants.ENTER_EDIT).pipe(
    withLatestFrom(state$),

    map(([action]) => ({
      type: Constants.ENTERED_EDIT,
      filterGroupId: action.id,
      isEditing: action.isEditing,
    })),
  )
export const epic = ReduxObservable.combineEpics(
  onEnterCue,
  onEnterLoad,
  addFilter,
  editFilter,
  filterAdded,
  loadSourceFilters,
  upsertSourceFilter,
  syncFilter,
  bulkFilterApplied,
  enterEdit,
  updateFilter,
)
